<template>
  <section id="contact">
    <ContactForm />
  </section>
</template>

<script>
import ContactForm from '@/components/ContactForm/ContactForm';
export default {
  data() {
    return {};
  },
  components: {
    ContactForm,
  },
};
</script>

<style scoped src="./Contact.css"></style>
